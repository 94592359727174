


























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyFieldItemResourcePartial} from '@/interfaces';
import {mdiAsterisk} from '@mdi/js';
import {isString} from 'lodash';

@Component
export default class CheckboxGroup extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readonly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;
  private iconRequired = mdiAsterisk;
  private selected: string[] = [];
  created() {
    if (this.value && isString(this.value)) {
      this.selected = JSON.parse(this.value);
    } else {
      this.selected = (this.value as unknown) as string[];
    }
  }
}
