

































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  JsonApiFilters,
  Snackbar,
  SubmissionResource,
  SurveyResource,
} from '@/interfaces';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';

import {submissionModule, surveyModule} from '@/store/modules';
import SubmissionStatusChip from '@/components/ui/SubmissionStatusChip.vue';
import {Prop} from 'vue-property-decorator';
import {ResourceType, Route} from '@/enums';
import DateUserCell from '@/components/ui/DateUserCell.vue';
import {isOpen, sleep} from '@/utility';
import {submissionApi} from '@/api';
import {dateFormat} from '@/filters';
import ContactSubmittersDialog from '@/components/submissions/ContactSubmittersDialog.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';

@Component({
  components: {
    NotificationBar,
    ContactSubmittersDialog,
    DateUserCell,
    SubmissionStatusChip,
    ButtonEditAction,
  },
})
export default class SubmissionList extends Vue {
  @Prop({default: () => ({})}) private filters!: JsonApiFilters;
  @Prop() private survey!: SurveyResource;
  private search = '';
  private headers: Record<string, string>[] = [];
  private selectedSubmissionIds: string[] = [];
  private moving: string | false = false;
  private bucket = 0;
  private buckets = ['submitted', 'approved', 'rejected', 'all'];
  private contactSubmittersDialogShown = false;
  private snackbar: Snackbar = {visible: false, message: ''};

  get surveyId(): string {
    return this.survey.id;
  }

  get submissions(): SubmissionResource[] {
    return submissionModule.list.reverse();
  }

  get loading(): boolean {
    return submissionModule.listing;
  }

  async created() {
    if (isOpen(this.survey.attributes.type)) {
      this.headers = [
        {text: 'Submission Date', value: 'created'},
        {
          text: 'Data Sample',
          value: 'attributes.dataSample',
          cellClass: 'no-wrap',
        },
        {text: 'Status', value: 'attributes.bucket'},
        {text: 'IP Address', value: 'attributes.ipAddress'},
      ];
    } else {
      this.headers = [
        {text: 'Submission Date', value: 'created'},
        {
          text: 'Data Sample',
          value: 'attributes.dataSample',
          cellClass: 'no-wrap',
        },
        {text: 'Company Name', value: 'attributes.communitechCompany.name'},
        {text: 'Recipient', value: 'recipient'},
        {text: 'Status', value: 'attributes.bucket'},
        {text: 'IP Address', value: 'attributes.ipAddress'},
      ];
    }

    // Note: we don't need to call loadData here because a tab change happens
    // automatically  which triggers the loadData method
  }

  async loadData() {
    submissionModule.reset();
    await submissionModule.loadMulti({
      filters: {
        surveyId: this.surveyId,
        bucket: this.buckets[this.bucket],
      },
    });
  }

  view(item: SubmissionResource) {
    this.$router.push({name: Route.SubmissionsView, params: {id: item.id}});
  }

  itemSelected(item: {item: SubmissionResource; value: boolean}) {
    if (item.value) {
      this.selectedSubmissionIds.push(item.item.id);
    } else {
      this.selectedSubmissionIds = this.selectedSubmissionIds.filter(
        submissionId => submissionId !== item.item.id
      );
    }
  }

  async moveToBucket(bucket: string) {
    this.moving = bucket;
    if (process.env.NODE_ENV === 'development') {
      await sleep(2000);
    }
    await submissionApi.postActions({
      type: ResourceType.Action,
      attributes: {
        action: 'move',
        destinationBucket: bucket,
        submissionIds: this.selectedSubmissionIds,
      },
    });
    this.moving = false;
    await this.loadData();
  }

  async exportToCsv() {
    const response = await submissionApi.listTextCsv({
      filters: {surveyId: this.surveyId},
    });
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response);
    anchor.target = '_blank';

    const surveyName = surveyModule.find(this.surveyId).attributes.name;
    anchor.download = `${surveyName} ${dateFormat(
      new Date(),
      'yyyy-MM-dd'
    )}.csv`;
    anchor.click();
  }

  private bucketFilter(value: number) {
    this.bucket = value;
    this.loadData();
  }

  closeContactSubmittersDialog(message?: string) {
    this.contactSubmittersDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }
}
