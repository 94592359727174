



















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {surveyPageModule} from '@/store/modules';
import {SelectItem} from '@/interfaces';

@Component({components: {RequiredLabel}})
export default class SurveyPageAutocomplete extends Vue {
  @Prop() value!: string;
  @Prop({default: 'Jump to Survey Page'}) label!: string;

  get list(): SelectItem[] {
    return [
      ...[{text: 'None', value: null}],
      ...surveyPageModule.list.map(item => ({
        text: item.attributes.label,
        value: Number(item.id),
      })),
    ];
  }

  get listing(): boolean {
    return surveyPageModule.listing;
  }

  async created(): Promise<void> {
    console.log(this.list);
    if (this.list.length === 0) {
      await surveyPageModule.loadMulti({append: true});
    }
  }

  update(value: string): void {
    this.$emit('input', value);
  }
}
