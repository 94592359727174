



















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {UserResourcePartial} from '@/interfaces';
import {dateFormat, timeAgo} from '@/filters';

@Component
export default class DateUserCell extends Vue {
  @Prop() private date!: Date;
  @Prop({default: 'created'}) private action!: string;
  @Prop({default: true}) private useTimeAgo!: boolean;
  @Prop() private user!: UserResourcePartial;
  private dateFormat = dateFormat;
  private timeAgo = timeAgo;

  get dateAgo() {
    return this.timeAgo(this.date);
  }
}
